
import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from "react-i18next";
import { orderBy } from 'lodash';

export const DroppableData = (props) => {
  const { title, minHeight, maxHeight, list, droppableId } = props;
  const { t } = useTranslation();
    return (
      <div>
        <label>{title}</label>
        <Droppable
          droppableId={droppableId}
          type="variables"
        >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div
              style={{border: "1px solid #CCCCCC", minHeight: minHeight, maxHeight: maxHeight, overflow: "auto"}}
              className={"form-control custom-droppable px-3"}>
              <fieldset>
                {list && orderBy(list, el => el.order)?.map((item, index) => {
                  return(
                    <div key={index}>
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                        type="variables">
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                          {item &&
                            <div className="pt-1">{item?.question.question_text} ({t(`${item.question.question_type}`)})</div>
                          }
                          </div>
                        )}
                      </Draggable>
                    </div>
                  )
                })}
              </fieldset>
            </div>
            {provided.placeholder}
          </div>
        )}
        </Droppable>
      </div>
    )
}

DroppableData.propTypes = {
    minHeight: PropTypes.string,
    maxHeight: PropTypes.string,
    droppableId: PropTypes.string,
    title: PropTypes.string,
    list: PropTypes.array,
};
