import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from "react-i18next";
import { DroppableData } from './DragAndDrop';


const DragQuestions = (props) => {

  const { questions, onMoveEnd } = props 
  const { t } = useTranslation();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((el, i)=> {
      const elTmp = el;
      elTmp.order = i;
      return el;
    })
    
    return result;
  };
  
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const result1 = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    onMoveEnd(result1)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row mt-1">
        <div className="col-lg-12">
          <div className="row mt-0">
            <div className="col-md-6">
              <label>{t('questions')}</label>
              <DroppableData
                minHeight="500px"
                maxHeight="500px"
                list={questions}
                droppableId={'available'}
              />
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
    )
}
export default DragQuestions;