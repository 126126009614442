import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import axios from 'axios';

class Raffles {

  //COINS
  async getRaffles() {
    const endpoint = endpoints.get_raffles;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createRaffle(obj) {
    const endpoint = endpoints.create_raffle;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'raffle'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'raffle'})
      })
    }
  }


  async editRaffle(id, obj) {
    const endpoint = endpoints.edit_raffle + id ;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'raffle'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'raffle'})

      })
    }
  }

  async deleteRaffle(id) {
    const endpoint = endpoints.delete_raffle + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'raffle'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'raffle'})
      })
    }
  }

  async exportRaffleParticipants(id, param, queryObj) {
    const endpoint = endpoints.export_raffle_participants + id;
    const res = await RestHelper.get(endpoint, param,
      {
        params: queryObj,
        responseType: 'blob'
      });
    if (res.status !== 200) {
       throw new Error(i18next.t('messages.obj_could_not_be_downloaded', { obj: 'participants_file'}))
    }
    else {
      return ({
				res,
        message: i18next.t('messages.obj_downloaded', { obj: 'participants_file'})
      })
    }
  }

  async addRaffleWinner(id, obj) {
    const endpoint = endpoints.add_raffle_winner + id;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_added', { obj: 'winner'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_added', { obj: 'winner'})
      })
    }
  }


}

export default new Raffles();
