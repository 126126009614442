import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import CampaignDetails from './CampaignDetails';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { PencilFill, Plus } from 'react-bootstrap-icons';
import { X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  //const [questionCategories, setQuestionCategories] = useState([]);
  const [questionBanks, setQuestionBanks] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const [key, setKey] = useState('active');
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      getQuestionBanks();
      getCampaigns();
    };
    fetchData();
  }, []);

  const getCampaigns = async() =>  {
    try {
      let res = await APIs.getCampaigns();
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  }


  const getQuestionBanks = async() =>  {
    try {
      let res = await APIs.getQuestionBanks();
      setQuestionBanks(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createCampaign = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      let res = await withToast(APIs.createCampaign(obj));
      setSelectedCampaign(res.data);
      getCampaigns();
    } catch (err) {
      console.error(err);
    }
  }

  const editCampaign = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(APIs.editCampaign(obj.id, obj));
      getCampaigns();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteCampaign = async(id) =>  {
    try {
      await withToast(APIs.deleteCampaign(id));
      setSelectedCampaign(null);
      setShowDeleteModal(false);
      getCampaigns();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div className="pull-right">
          {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('available_from'),
      accessor: "available_from",
      Cell: cell,
    },
    {
      Header: t('available_until'),
      accessor: "available_until",
      Cell: cell,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill className="custom-icon me-2" color="gray" size={16}
            onClick={() => {setShowDetailsModal(true), setSelectedCampaign(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}/>
          </div>
        );
      },
      disableFilters: true,
      width: 30,
      minWidth: 30,
      maxWidth: 30
    },
  ]

  const data = React.useMemo(() => campaigns)
  return(
    <>
      <h3>{t('campaigns')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowDetailsModal(true), setSelectedCampaign(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Tabs id="content"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="active" title={t('active')}>
          {key === 'active' &&
            <CheckboxTablePaginated
              columns={columns}
              data={campaigns.filter(el => el.status === 'active')}
              total={false}
              filterTable={true}
              selectedId={selectedCampaign?.id}
            />
          }
        </Tab>
        <Tab eventKey="development" title={t('development')}>
          {key === 'development' &&
            <CheckboxTablePaginated
              columns={columns}
              data={campaigns.filter(el => el.status === 'development')}
              total={false}
              filterTable={true}
              selectedId={selectedCampaign?.id}
            />
          }
        </Tab>
        <Tab eventKey="testing" title={t('testing')}>
          {key === 'testing' &&
          <CheckboxTablePaginated
            columns={columns}
            data={campaigns.filter(el => el.status === 'testing')}
            total={false}
            filterTable={true}
            selectedId={selectedCampaign?.id}
          />
          }
        </Tab>
        <Tab eventKey="expired" title={t('expired')}>
          {key === 'expired' &&
          <CheckboxTablePaginated
            columns={columns}
            data={campaigns.filter(el => el.status === 'expired')}
            total={false}
            filterTable={true}
            selectedId={selectedCampaign?.id}
          />
          }
        </Tab>
      </Tabs>
      {showDetailsModal &&
         <CampaignDetails
            onCancel={() => setShowDetailsModal(false)}
            selectedRow={selectedCampaign}
            onCreate={obj => createCampaign(obj)}
            onEdit={obj => editCampaign(obj)}
            questionBanks={questionBanks}
          />
        }
      {showDeleteModal && <DeleteModal
        onOK={() => deleteCampaign(objToDelete)}
        onCancel={() => setShowDeleteModal(false)}
      />}
    </>
  )
}
export default Campaigns;
