import React, { useState, useEffect} from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import APIs from '../../api/apiCalls';
import CoinTypeDetails from './CoinTypeDetails';
import { DeleteModal } from '../../components/Modal';
import { withToast } from '../../api/util';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';

const PhysicalCoins = () => {
  const [coinTypes, setCoinTypes] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [themes, setThemes] = useState([]);
  const [selectedCoinType, setSelectedCoinType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const [showCoinDetails, setShowCoinDetails] = useState(false);
  const [key, setKey] = useState('active');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const [coinsRes, campaignsRes, themesRes, categoriesRes] = await Promise.all([
        CoinsAPI.getCoinTypes({physical: 1}),
        APIs.getCampaigns(),
        CoinsAPI.getThemes(),
        //APIs.getQuestionCategories()
      ])
      setCampaigns(campaignsRes.data);
      setCoinTypes(coinsRes.data.filter(coin => coin.is_physical));
      setThemes(themesRes.data);
    }
    fetchData();
  }, [])

  const getCoins = async() =>  {
    try {
      let res = await CoinsAPI.getCoinTypes({physical: 1});
      setCoinTypes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createCoinType = async(obj) =>  {
    setShowCoinDetails(false);
    try {
      const res = await withToast(CoinsAPI.createCoinType(obj));
      getCoins();
      setSelectedCoinType(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const editCoinType = async(obj) =>  {
    setShowCoinDetails(false);
    try {
      await withToast(CoinsAPI.editCoinType(selectedCoinType.id, obj));
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteCoinType = async(id) =>  {
    setShowDeleteModal(false);
    try {
      await CoinsAPI.deleteCoinType(id);
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }


  const cell = (row) => {
    return(
      <div>
        {row.value}
      </div>
    )
  }

  const cellNum = (row) => {
    return (
      <div className="float-end">
       {row.value}
      </div>
    );
  }


  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('campaign'),
      accessor: "campaign.name",
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...campaigns.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: t('date_of_issue'),
      accessor: "date_of_issue",
      Cell: cell,
    },
    {
      Header: t('num_coins'),
      accessor: "num_coins",
      Cell: cellNum,
    },
    {
      Header: t('quantity_in_use'),
      accessor: "quantity_in_use",
      Cell: cellNum,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        if(row.row.isGrouped) return <></>
        return (
          <div className="text-center custom-actions-column">
            <PencilFill className="custom-icon me-2" color="gray" size={16}
              onClick={() => {setShowCoinDetails(true), setSelectedCoinType(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40
    }
  ]

  return(
    <>
      <h3>{t('physical_coins')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setSelectedCoinType(null), setShowCoinDetails(true)}}>
        <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Tabs id="content"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="active" title={t('active')}>    
          <CheckboxTablePaginated
            columns={columns}
            data={coinTypes?.filter(el => el.status === 'active')}
            selectedId={selectedCoinType?.id}
            total={false}
            filterTable={true}
            pagination={true}
            //initialState={{groupBy: ["campaign.name"]}}
          />
        </Tab>
        <Tab eventKey="development" title={t('development')}>
          <CheckboxTablePaginated
            columns={columns}
            data={coinTypes?.filter(el => el.status === 'development')}
            selectedId={selectedCoinType?.id}
            total={false}
            filterTable={true}
            pagination={true}
          />
        </Tab>
        <Tab eventKey="testing" title={t('testing')}>
          <CheckboxTablePaginated
            columns={columns}
            data={coinTypes?.filter(el => el.status === 'testing')}
            selectedId={selectedCoinType?.id}
            total={false}
            filterTable={true}
            pagination={true}
          />
        </Tab>
      </Tabs>   
      {showDeleteModal && <DeleteModal
        onOK={() => deleteCoinType(objToDelete)}
        onCancel={() => setShowDeleteModal(false)}
      />}
      {showCoinDetails &&  <CoinTypeDetails
        onCancel={() => setShowCoinDetails(false)}
        selectedRow={selectedCoinType}
        onCreate={obj => createCoinType(obj)}
        onEdit={obj => editCoinType(obj)}
        campaigns={campaigns}
        themes={themes}
        is_physical={true}
      />}
    </>
  )
}
export default PhysicalCoins;
