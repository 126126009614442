import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import APIs from '../../api/apiCalls';
import { Formik } from 'formik';
import { FormTextField } from '../../components/FormField';
import yup from "../../yupUtils";

const CategoryDetails = (props) => {
  const { selectedRow, onCreate, onEdit, onClose } = props;
  const [questionBankTmp, setQuestionBankTmp] = useState(null)
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    name: '',
  }

  const schema = yup.object({
    name: yup.string().required()
  })

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
        setQuestionBankTmp(selectedRow)
      }
      else {
        setQuestionBankTmp(initialState)
      }
    };
    setData();
  }, [props])

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(values?.id) {
        onEdit(values)
      }
      else {
        onCreate(values)
      }
    }
  }

  const renderForm = () => {
    return (
      <Form>
        <FormTextField
          title={t('name')}
          name={"name"}
        />
      </Form>
    )
  }

  return(
    <>
     <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={questionBankTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values)
        }}
      >
        {({
          handleSubmit,
          dirty,
        }) => (
        <>
          <CustomModal
            title={questionBankTmp?.id ? t('edit_obj', {obj: 'question_bank'}) : t('new_obj', {obj: 'question_bank'})}
            content={renderForm()}
            contentStyle={{fontSize: "12px"}}
            onCancel={onClose}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}
            disabledOKButton={!dirty}
            size={"sm"}
            modified={dirty}
          />
        </>
      )}
    </Formik>
    </>
  )
}
export default CategoryDetails;
