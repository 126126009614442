import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';
import APIs from '../api/apiCalls';


 const LanguageSwitcher = (props) => {
    const [language, setLanguage] = useState('');
    const { i18n } = useTranslation();

    const setLanguageBE = async (lng) => {
      await APIs.setLanguage({language: lng})
    }

    useEffect(() => {
      const lng = localStorage.getItem("language");
      i18n.changeLanguage(lng);
      setLanguage(lng);
      setLanguageBE(lng);

    }, []);

    const changeLanguage = async(e) => {
      const lng = e.target.value;
      localStorage.setItem("language", lng);
      i18n.changeLanguage(lng);
      setLanguage(lng)
      setLanguageBE(lng);
    };

    return (
    <Form>
       <Form.Control as="select" value={language ? language : navigator.language} onChange={changeLanguage}>
          <option value="en">EN</option>
          <option value="hu">HU</option>
        </Form.Control>
    </Form>
    )
}

export default (LanguageSwitcher);
