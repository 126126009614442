import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import DragQuestionsSimple from './DragQuestionsSimple';
import { FormNumber, FormSelectField } from '../../components/FormField';
import { difficulty } from '../constants';

const QuizPage = (props) => {  
  const { selectedQuizPage, formValues, index, setFieldValue } = props;
  const [quizPageTmp, setQuizPageTmp] = useState(null);
  const { t } = useTranslation();

  const initialState = {
    min_difficulty: 1,
    max_difficulty: 2,
    num_questions: 2,
    quiz: '',
    quiz_page_questions: [],
    success_limit: 90,
    str_difficulty: 2
  }

  useEffect(() => {
    const setData = async () => {
      if(selectedQuizPage?.id) {
        setQuizPageTmp(selectedQuizPage)
      }
      else {
        setQuizPageTmp(initialState)                                                                                                                                                                                                                                              
      }
    };
    setData();
  }, [index])

  const renderForm = () => {
    return (
      <>
        <Row>
          <Col lg={3}>
            <FormSelectField
              name={`quiz_pages.${index}.str_difficulty`} 
              title={t('difficulty')}
              options={difficulty}
              disabled={selectedQuizPage?.id}
            />
            {/* <FormNumber 
              name={`quiz_pages[${index}].min_difficulty`} 
              title={t('min_difficulty')}
              disabled={selectedQuizPage?.id}
            /> */}
          </Col>
          {/* <Col>
            <FormNumber 
              name={`quiz_pages[${index}].max_difficulty`} 
              title={t('max_difficulty')}
              disabled={selectedQuizPage?.id}
              //value={quizPageTmp?.max_difficulty || ''} 
            />
          </Col> */}
           <Col lg={3}>
            <FormNumber 
              name={`quiz_pages[${index}].num_questions`} 
              title={t('num_questions')}
              disabled={selectedQuizPage?.id}
            />
          </Col>
          <Col lg={3}>
            <FormNumber 
              name={`quiz_pages[${index}].success_limit`} 
              title={t('success_limit')}
            />
          </Col>
        </Row>
        <DragQuestionsSimple
          onMoveEnd={el => setFieldValue(`quiz_pages[${index}].quiz_page_questions`, el)}
          questions={formValues?.quiz_pages[index]?.quiz_page_questions} 
        />
      </>
    )
  } 

  return(
    <>
      {renderForm()}
    </>
  )
}
export default QuizPage;
