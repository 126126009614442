import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import AgeGroupDetails from './AgeGroupDetails';
import { Row, Col, Button } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import { CustomTable } from '../../components/ReactTable/ReactTable';

const AgeGroups = () => {
  const { t } = useTranslation();
  const [ageGroups, setAgeGroups] = useState([]);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      getAgeGroups()
    };
    fetchData();
  }, []);

  const getAgeGroups = async() =>  {
    try {
      let res = await APIs.getAgeGroups();
      setAgeGroups(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const createAgeGroup = async(obj) =>  {
    setShowDetails(false);
    try {
      let res = await withToast(APIs.createAgeGroup(obj));
      setSelectedAgeGroup(res.data);
      getAgeGroups()
    } catch (err) {
      console.error(err);
    }
  }

  const editAgeGroup = async(obj) =>  {
    setShowDetails(false);
    try {
      await withToast(APIs.editAgeGroup(obj.id, obj));
      getAgeGroups();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteAgeGroup = async(id) =>  {
    try {
      await withToast(APIs.deleteAgeGroup(id));
      setSelectedAgeGroup(null);
      setShowDeleteModal(false);
      getAgeGroups();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>       
        {row.value}
      </div>
    )
  }

  const cellNum = (row) => {
    return(
      <div className="float-end">
        {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('min_age'),
      accessor: "min_age",
      Cell: cellNum,
    },
    {
      Header: t('max_age'),
      accessor: "max_age",
      Cell: cellNum,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <>
            <PencilFill className="custom-icon me-2" color="gray" size={16} 
            onClick={() => {setShowDetails(true), setSelectedAgeGroup(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}/>
          </>
        );
      },      
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40
    },   
  ]

  const data = React.useMemo(() => ageGroups)
  return(
    <>
      <h3>{t('age_groups')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowDetails(true), setSelectedAgeGroup(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Row>
        <Col lg={6}>         
          <CustomTable 
            columns={columns} 
            data={data} 
            trClick={async (row) => {}}
            onSelect={async (row) => {}}
            selectedId={selectedAgeGroup?.id}            
          />
        </Col>
        {showDetails &&  <AgeGroupDetails 
          onCancel={() => setShowDetails(false)} 
          selectedRow={selectedAgeGroup}
          onCreate={obj => createAgeGroup(obj)}
          onEdit={obj => editAgeGroup(obj)}
        />}
        {showDeleteModal && <DeleteModal
          onOK={() => deleteAgeGroup(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />}
      </Row>
    </>
  )
}
export default AgeGroups;
