import i18next from "../i18n";

export const statusOptions = [ 
    { label: i18next.t('development'), value: 'development' },
    { label: i18next.t('testing'), value: 'testing' },
    { label: i18next.t('active'), value: 'active' },
    { label: i18next.t('expired'), value: 'expired' },
]

export const statusOptionsFilter = [ 
    { label: i18next.t('choose'), value: '' },
    { label: i18next.t('development'), value: 'development' },
    { label: i18next.t('testing'), value: 'testing' },
    { label: i18next.t('active'), value: 'active' },
    { label: i18next.t('expired'), value: 'expired' },
]

export const questionTypes = [
    {label: i18next.t('multichoice'), value: 'multichoice'},
    {label: i18next.t('singlechoice'), value: 'singlechoice'},
    {label: i18next.t('ordering'), value: 'ordering', },
    {label: i18next.t('pairing'), value: 'pairing',},
    {label: i18next.t('dnd'), value: 'dnd',},
    {label: i18next.t('free'), value: 'free'},
]

export const difficulty = [
    {label: i18next.t('easy'), value: 2},
    {label: i18next.t('medium'), value: 5},
    {label: i18next.t('hard'), value: 8},
  ]