import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import QuizDetails from './QuizDetails';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { PencilFill, Plus } from 'react-bootstrap-icons';
import { X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import { CustomTable } from '../../components/ReactTable/ReactTable';

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [key, setKey] = useState('active');
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      getQuestionCategories();
      getCampaigns();
      getAgeGroups();
    };
    fetchData();
  }, []);

  const getCampaigns = async() =>  {
    try {
      let res = await APIs.getCampaigns();
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  }


  const getCampaignQuizzes = async(id) =>  {
    try {
      let res = await APIs.getCampaignQuizzes(id);
      setLoading(false)
      return res.data;
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const getQuestionCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories();
      setQuestionCategories(res.data.filter(cat => !cat.is_hidden))
    } catch (err) {
      console.error(err);
    }
  }

  const getAgeGroups = async() =>  {
    try {
      let res = await APIs.getAgeGroups();
      setAgeGroups(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const deleteQuiz = async(id) =>  {
    try {
      await withToast(APIs.deleteQuiz(id));
      setSelectedQuiz(null);
      onCloseModal();
      setQuizzes(await getCampaignQuizzes(selectedCampaign));
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>
          {row.value}
      </div>
    )
  }

  const columns1 = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    }
  ]

  const columns2 = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('category'),
      accessor: "category.name",
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...questionCategories.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: t('num_pages'),
      accessor: 'num_pages',
      Cell: cell,
    },
    {
      Header: t('num_questions'),
      accessor: 'num_questions',
    },
    {
      Header: t('age_group'),
      accessor: 'age_group.name',
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...ageGroups.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center custom-actions-column">
            <PencilFill className="custom-icon me-2" color="gray" size={16}
            onClick={() => {setShowModal('new_edit'), setSelectedQuiz(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setShowModal('delete'), setObjEditDelete(row.row.original.id)}}/>
          </div>
        );
      },
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40
    },
  ]

  const onCloseModal = () => {
    setShowModal('');
  }

  const onRefresh = async() => {
    if(selectedCampaign) setQuizzes(await getCampaignQuizzes(selectedCampaign));
  }

  const data = React.useMemo(() => quizzes)
  return(
    <>
      <h3>{t('quizzes')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setSelectedQuiz(null), setShowModal('new_edit')}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Row>
        <Col lg={2}>
          <CustomTable
            columns={columns1}
            data={campaigns}
            trClick={async (row) =>  {}}
            onSelect={async(data) => {
              if(data) {
                setSelectedCampaign(data)
                setQuizzes(await getCampaignQuizzes(data));
              }

            }}
            selectedId={selectedCampaign}
          />
        </Col>
        <Col lg={10}>
          {showModal === 'new_edit' &&  <QuizDetails
              onCancel={() => onCloseModal()}
              selectedRow={selectedQuiz}
              campaigns={campaigns}
              questionCategories={questionCategories}
              onChangeQuestionCategory={questions => setAllQuestions(questions)}
              onRefresh={()=> onRefresh()}
              ageGroups={ageGroups}
            />
          }
          {selectedCampaign &&
          <>
           <Tabs id="content"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
           >
              <Tab eventKey="active" title={t('active')}>
                {key === "active" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'active')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                  />
                }
              </Tab>
              <Tab eventKey="development" title={t('development')}>
                {key === "development" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'development')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                  />
                }
              </Tab>
              <Tab eventKey="testing" title={t('testing')}>
                {key === "testing" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'testing')}
                    total={false}
                    filterTable={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                  />
                }
              </Tab>
              <Tab eventKey="expired" title={t('expired')}>
                {key === "expired" &&
                  <CheckboxTablePaginated
                    columns={columns2}
                    data={quizzes?.filter(el => el.status === 'expired')}
                    total={false}
                    filterTable={true}
                    pagination={true}
                    selectedId={selectedQuiz?.id}
                    loading={loading}
                  />
                }
              </Tab>
            </Tabs>
          </>
        }
        </Col>
      </Row>
      {showModal === 'delete' && <DeleteModal
        onOK={() => deleteQuiz(objEditDelete)}
        onCancel={() => onCloseModal()}
      />}
    </>
  )
}
export default Quizzes;
