import React, { useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import APIs from '../../api/apiCalls';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import CoinTypeDetails from './CoinTypeDetails';
import { DeleteModal } from '../../components/Modal';
import { CustomNumberFormatSimple } from '../../components/NumberFormat';
import { withToast } from '../../api/util';
import CoinInstances from './CoinInstances';

const DigitalCoinTypes = () => {
  const [coinTypes, setCoinTypes] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCoinType, setSelectedCoinType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const [showCoinDetails, setShowCoinDetails] = useState(false);
  const [showCoinInstances, setShowCoinInstances] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const [coinsRes, campaignsRes] = await Promise.all([
        CoinsAPI.getCoinTypes({physical: 0}),
        APIs.getCampaigns(),
      ])
      setCoinTypes(coinsRes.data);
      setCampaigns(campaignsRes.data);
    }
    fetchData();
  }, [])

  const getCoins = async() =>  {
    try {
      let res = await CoinsAPI.getCoinTypes({physical: 0});
      setCoinTypes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getCampaigns = async() =>  {
    try {
      let res = await APIs.getCampaigns();
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createCoinType = async(obj) =>  {
    setShowCoinDetails(false);
    try {
      await withToast(CoinsAPI.createCoinType(obj));
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }

  const editCoinType = async(obj) =>  {
    setShowCoinDetails(false);
    try {
      await withToast(CoinsAPI.editCoinType(selectedCoinType.id, obj));
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteCoinType = async(id) =>  {
    setShowDeleteModal(false);
    try {
      await CoinsAPI.deleteCoinType(id);
      getCoins();
    } catch (err) {
      console.error(err);
    }
  }


  const cell = (row) => {
    return(
      <div>
        {row.value}
      </div>
    )
  }

  const cellNum = (row) => {
    return (
      <div className="float-end">
        <CustomNumberFormatSimple
          displayType="text"
          value={row.value}
          integer={true}
        />
      </div>
    );
  }


  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
    {
      Header: t('campaign'),
      accessor: "campaign.name",
    },
    {
      Header: t('date_of_issue'),
      accessor: "date_of_issue",
      Cell: cell,
    },
    {
      Header: t('num_coins'),
      accessor: "num_coins",
      Cell: cellNum,
    },
    {
      Header: t('quantity_in_use'),
      accessor: "quantity_in_use",
      Cell: cellNum,
    },
    {
      Header: t('used_coin_instances'),
      accessor: ' ',
      Cell: (row) => {
        if(row.row.isGrouped) return <></>
        return(
          <div className="text-center">
            <Button size="sm" className="custom-button" 
            onClick={() => {setSelectedCoinType(row.row.original), setShowCoinInstances(true)}} > 
            {t('used_coin_instances')}</Button>    
          </div>
        )
      },
      width: 100,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        if(row.row.isGrouped) return <></>
        return (
          <div className="text-center custom-actions-column">
            <PencilFill className="custom-icon me-2" color="gray" size={16}
              onClick={() => {setShowCoinDetails(true), setSelectedCoinType(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40
    }
  ]

  return(
    <>
      <h3>{t('nft')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setSelectedCoinType(null), setShowCoinDetails(true)}}>
        <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>

      <CustomTable
        columns={columns}
        data={coinTypes}
        trClick={async (row) => {}}
        onSelect={() => null}
        selectedId={selectedCoinType?.id}
        initialState={{groupBy: ["campaign.name"]}}
      />
       {showCoinInstances && <CoinInstances
        selectedCoinType={selectedCoinType}
        onOK={() => setShowCoinInstances(false)}
        onCancel={() => setShowCoinInstances(false)}
      />}
      {showDeleteModal && <DeleteModal
        onOK={() => deleteCoinType(objToDelete)}
        onCancel={() => setShowDeleteModal(false)}
      />}
      {showCoinDetails &&  <CoinTypeDetails
        onCancel={() => setShowCoinDetails(false)}
        selectedRow={selectedCoinType}
        onCreate={obj => createCoinType(obj)}
        onEdit={obj => editCoinType(obj)}
        campaigns={campaigns}
        is_physical={false}
      />}
    </>
  )
}
export default DigitalCoinTypes;
