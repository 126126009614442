import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import ThemeDetails from './ThemeDetails';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { PencilFill, Plus } from 'react-bootstrap-icons';
import { X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import ImportThemes from './ImportThemes';

const Themes = () => {  
  const { t } = useTranslation();
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      getThemes()
    };
    fetchData();
  }, []);

  const getThemes = async() =>  {
    try {
      let res = await CoinsAPI.getThemes();
      setThemes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createTheme = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(CoinsAPI.createTheme(obj));
      getThemes();
    } catch (err) {
      console.error(err);
    }
  }

  const editTheme = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(CoinsAPI.editTheme(obj.id, obj));
      getThemes();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteTheme = async(id) =>  {
    try {
      await withToast(CoinsAPI.deleteTheme(id));
      setSelectedTheme(null);
      setShowDeleteModal(false);
      getThemes();
    } catch (err) {
      console.error(err);
    }
  }

  const importThemes = async(obj) =>  {
    const data = new FormData();
    data.append('themes', obj.themes);
    try {
      await withToast(CoinsAPI.importThemes(data));
      setShowImportModal(false);
      getThemes();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>       
          {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    }, 
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <>
            <PencilFill className="custom-icon me-2" color="gray" size={16} 
            onClick={() => {setShowDetailsModal(true), setSelectedTheme(row.row.original)}}/>         
            <X className="custom-icon" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}
            /> 
          </>
        );
      },      
      disableFilters: true,
      width: 20,
      minWidth: 20,
      maxWidth: 20
    }   
  ]
  

  const data = React.useMemo(() => themes)
  return(
    <>
      <h3>{t('themes')}</h3>
      <Button size="sm" className="mb-2 custom-button me-2" onClick={()=>{setShowDetailsModal(true), setSelectedTheme(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowImportModal(true), setSelectedTheme(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.import')}
      </Button>
      <Row>
        <Col lg={6}>
          <CustomTable 
            columns={columns} 
            data={data} 
            trClick={() => {}}
            onSelect={() => {}}
            selectedId={selectedTheme?.id}
          />
        </Col>
      </Row>
      {showDetailsModal && 
        <ThemeDetails
          onCancel={() => setShowDetailsModal(false)} 
          selectedRow={selectedTheme}
          onCreate={obj => createTheme(obj)}
          onEdit={obj=> editTheme(obj)}
       />
      }
      {showImportModal && <ImportThemes 
        onCancel={() => setShowImportModal(false)}
        onOK={file => importThemes(file)}
      />}      
      {showDeleteModal && <DeleteModal 
          onOK={() => deleteTheme(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />}
    </>
  )
}
export default Themes;
