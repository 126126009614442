import React, { useEffect, useState } from 'react';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import {Button } from 'react-bootstrap';
import { Download, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import GenerateImprints from './GenerateImprints';
import ImprintsList from './ImprintsList';
import AddImprint from './AddImprint';
import { CustomNumberFormatSimple } from '../../components/NumberFormat';
import { withToast } from '../../api/util';
import { download_file } from '../Raffles/downloadFile';


const Imprints = (props) => {

  const [selectedCoinType, setSelectedCoinType] = useState(null);
  const [coinTypes, setCoinTypes] = useState([]);
  const [selectedImprint, setSelectedImprint] = useState(null);
  const [showGenerateImprintsModal, setShowGenerateImprintsModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const { t } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      getCoinTypes({physical: 1});
      setRefresh(false);
    }
    fetchData();
  }, [refresh])

  const getCoinTypes = async() =>  {
    try {
      let res = await CoinsAPI.getCoinTypes({physical: 1});
      setCoinTypes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const exportImprints = async (id) => {
    try {
      const res = await withToast(CoinsAPI.exportImprints(id));
      download_file(res.res);
    } catch (err) {
      console.error(err);
    }
  }

  const cellNum = (row) => {
    return (
      <div className="float-end">
        <CustomNumberFormatSimple
          displayType="text"
          value={row.value}
          integer={true}
        />
      </div>
    );
  }

  const columns1 = [
    {
      Header: t('coin_type'),
      accessor: 'name'
    },
    {
      Header: t('imprints_number'),
      accessor: 'quantity_in_use',
      Cell: cellNum,
    },
    {
      Header: t('imprint_instances'),
      accessor: ' ',
      Cell: (row) => {
        return(
          <div className="text-center">
            <Button size="sm" className="custom-button" 
            onClick={() => setSelectedCoinType(row.row.original)} > 
            {t('imprint_instances_list')}</Button>   
          </div>
        )
      },
      width: 100,
    },
    {
      width: 30,
      Header: t('export_imprints'),
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <Download className="custom-icon me-3" color="#AF9B78" size={18} onClick={()=> exportImprints(row.row.original.id)} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <h3>{t('imprint_instances')}</h3>
      <Button size="sm" className="mb-2 custom-button me-2" onClick={()=>{setSelectedImprint({
              id: null,
              serial_number: '',
              coin_type: '',
              picture: '',
              desc: ''
            })}}>
        <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Button size="sm" className="mb-2 custom-button" onClick={() => setShowGenerateImprintsModal(true)}>
        {t('buttons.generate')}
      </Button>
       <CustomTable
        columns={columns1}
        data={coinTypes}
        trClick={async (row) => {}}
        onSelect={() => null}
        selectedId={null}
      />
      {selectedImprint &&
        <AddImprint 
          coinTypes={coinTypes}
          selectedImprint={selectedImprint}
          onCancel={() => setSelectedImprint(null)}
          onRefresh={() => setSelectedImprint(null)}
        />
      }
      {selectedCoinType &&  <ImprintsList 
        selectedCoinType={selectedCoinType}
        onCancel={() => setSelectedCoinType(null)} 
        coinTypes={coinTypes}
      />}
      {showGenerateImprintsModal && <GenerateImprints 
        coinTypes={coinTypes}
        onCancel={() => setShowGenerateImprintsModal(false)}
        onRefresh={() => getCoinTypes()}
      />}
  </>
  )
}

export default Imprints;
