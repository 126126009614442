import React, { useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { SelectForm } from "./SelectReact";
import { DatePicker } from './DatePicker'
import { useTranslation } from 'react-i18next';
import { CustomNumberFormat } from "./NumberFormat";
import { Editor } from '@tinymce/tinymce-react';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

export const FormTextField = ({
  as,
  md,
  title,
  name,
  type,
  inputGroupPrepend,
  onChange,
  disabled,
  placeholder,
  showEyeIcon,
  onClickIcon,
  changeIcon,
  ...props
}) => {
  const onChangeObj = {};
  if(onChange){
    onChangeObj.onChange = onChange;
  }
  const { t } = useTranslation();
  return (
    <Field name={name}>
        {({ field, form, meta }) => {
        //const isValid = !form.errors[field.name];
        //const isInvalid = form.touched[field.name] && !isValid;
        const isValid = !meta.error;
        const isInvalid = meta.touched && !isValid;
        return (
          <Form.Group md={md} controlId={name}>
            {title && <Form.Label>{title}</Form.Label>}
            <InputGroup>
              {inputGroupPrepend}
              <Form.Control
                as={as}
                {...field}
                value={field?.value ? field.value : ''}
                type={type}
                disabled={disabled}
                placeholder={placeholder ? placeholder : t(`${name}`)}
                isInvalid={isInvalid}
                //feedback={form.errors[field.name]}
                feedback={meta.error}
                autoComplete={props.autoComplete ? props.autoComplete : null}
                {...onChangeObj}
                {...props}
              />
              {showEyeIcon && (changeIcon ? <Eye className="custom-icon eye-icon" size={20} onClick={onClickIcon}/>
             : <EyeSlash className="custom-icon eye-icon" size={20} onClick={onClickIcon}/>)}
              <Form.Control.Feedback type="invalid" style={showEyeIcon ? {whiteSpace: "pre-wrap", lineHeight: "0.9"} : null} >
                {/* {form.errors[field.name]} */}
                {meta.error}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      }}
    </Field>
  );
};

export const FormSelectField = ({
  title,
  options,
  name,
  onChange,
  isMulti,
  ...props
}) => {

  return (
    <Field name={name}>
        {({ field, form }) => {

        const isValid = !form.errors[field.name];
        const isInvalid = !isValid && form.touched[field.name];

        const onChange1 = (option) => {
          form.setFieldValue(
              field.name,
              isMulti ? option.map((item) => item.value) : option.value
          );

          if (onChange !== undefined) {
              onChange(field.name, option.value);
          }
        };

        const getValue = () => {
          if (options && options.length > 0) {
            if(field.value?.id) {
              field.value = field.value.id
            }
            else {
              field.value = field.value
            }
            return isMulti
              ? options.filter(option => field.value?.indexOf(option.value) >= 0)
              : options.find(option => option.value === field.value);
          } else {
            return isMulti ? [] : '';
          }
        };

        return (
          <Form.Group controlId={name}>
              <SelectForm
                title={title}
                name={name}
                id={name}
                options={options}
                isMulti={isMulti}
                //value={value}
                value={getValue()}
                onChange={onChange1}
                // isValid={form.touched[field.name] && isValid}
                //value={(options ? options.find(option => option.value === field.value) : '') as any}
                //onChange={option => form.setFieldValue(field.name, option.value)}
                hasError={isInvalid}
                {...props}
              />
              {isInvalid && (
                <div style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }}>
                  {form.errors[field.name]}
                </div>
              )}
          </Form.Group>
        );
      }}
    </Field>
  );
};

export const FormDatePickerField = ({
  title,
  name,
  placeholder,
  ...props
}) => {
  return (
    <Field name={name}>
        {({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;

        const onChange = (option) => {
          form.setFieldValue(
              field.name,
              option.target.value
          );
        };

        return (
          <Form.Group controlId={name}>
              <DatePicker
                title={title}
                name={name}
                selected={field.value}
                onChange={onChange}
                errors={isInvalid}
                placeholder={placeholder}
                isInvalid={isInvalid}
                {...props}
              />
              {!!form.errors[field.name] && form.touched[field.name] && (
                <div style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }}>
                  {form.errors[field.name]}
                </div>
              )}
          </Form.Group>
        );
      }}
    </Field>
  );
};

export const FormCheckbox = ({
  label,
  name,
  ...props
}) => {
  return (
    <label>
    <Field name={name}>
        {({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <>
            <Form.Check
              {...field}
              value={field?.value ? field.value : false}
              checked={field?.value ? field.value : false}
              label={label}
              isInvalid={isInvalid}
              feedback={form.errors[field.name]}
              {...props}
            />
            <Form.Control.Feedback type="invalid">
              {form.errors[field.name]}
            </Form.Control.Feedback>
          </>

        );
      }}
    </Field>
  </label>
  );
};

export const FormNumber = ({
  name,
  title,
  disabled,
  isDecimal,
  ...props
}) => {
  return (
    <Field name={name}>
        {({ field, form, meta }) => {
        //const isValid = !form.errors[field.name];
        //const isInvalid = form.touched[field.name] && !isValid;
        const isValid = !meta.error;
        const isInvalid = meta.touched && !isValid;
        return (
          <>
            <CustomNumberFormat
              {...field}
              //value={field?.value ? field.value : ''}
              name={name}
              title={title}
              isInvalid={isInvalid}
              feedback={meta.error}
              integer={isDecimal ? false : true}
              disabled={disabled}
              {...props}
            />
            <Form.Control.Feedback type="invalid">
              {meta.error}
            </Form.Control.Feedback>
            {isInvalid && (
              <div style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }}>
                {meta.error}
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
};

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/insertdatetime';

//import 'tinymce/langs/hu_HU.js';

/* Import content css */
import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';


export const FormEditor = ({
  name,
  title,
  savedContent,
  ...props
}) => {
  const editorRef = useRef()
  return (
    <Field name={name}>
        {({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;

        const handleEditorChange = (content, editor) => {
          if(!editor.isNotDirty) {
            form?.setFieldValue(field.name, content);
          }
        };
        return (
          <>
            <Form.Label>{title}</Form.Label>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                onEditorChange={handleEditorChange}
                initialValue={savedContent || ''}
                disabled = {props.readonly}
                init={{
                  height: 500,
                  selector: 'textarea',
                  menubar: false,
                  language_url: "static/langs/hu_HU.js",
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount view'
                  ],
                  toolbar: 'undo redo | fontselect fontsizeselect formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | image media pageembed template link | code | preview',
                  skin: false,
                  content_css: false,
                  content_style: [contentCss, contentUiCss].join('\n'),
                  language: 'hu_HU',
                  automatic_uploads: true,
                  file_picker_types: 'image',
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                      var file = this.files[0];
                      var reader = new FileReader();
                      reader.onload = function () {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                
                    input.click();
                  },
                }}
              />
            {isInvalid && (
              <div style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }}>
                {form.errors[field.name]}
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
};
