import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { IsModifiedBadge } from './Badge';

const CustomModal = (props) => {
  const { t } = useTranslation();
  return(
    <Modal
      className={props.modalClass}
      enforceFocus={false}
      show={props.show && props.show === false ? false : true}
      onHide={props.onCancel}
      size={props.size ? props.size : !props.dialogClassName ? "lg" : ''}
      dialogClassName={props.dialogClassName && props.dialogClassName}
      //backdrop={props.backdrop}
      backdrop="static"
      keyboard={props.showHeaderCloseButton === false ? false : true}
      >
      {props.showHeaderCloseButton === false ?
      <Modal.Header
        closeButton={false}>
        {props.title && <Modal.Title as="h5">{props.title.length <= 50 ? props.title : props.title.slice(0, 50) + '...'}</Modal.Title>}
        <div className="modal-badge-right">{props.modified && <IsModifiedBadge text={t('unsaved')}/>}</div>
      </Modal.Header> :
      <Modal.Header
        closeButton={true} closeLabel=''>
        {props.title && <Modal.Title as="h5">{props.title.length <= 50 ? props.title : props.title.slice(0, 50) + '...'}</Modal.Title>}
        <div className="modal-badge-right">{props.modified && <IsModifiedBadge text={t('unsaved')}/>}</div>
      </Modal.Header>
      }
      <Modal.Body>
        <div style={props.contentStyle ? props.contentStyle : {fontSize: "18px"}}>
          {props.content}
        </div>
      </Modal.Body>
      <Modal.Footer style={{display:"block"}}>
      <ButtonToolbar style={{float:"left"}}>
        {props.showBackButton && <Button
          disabled={props.disabledBackButton ? props.disabledBackButton : false}
          onClick={props.onBack}
          //variant="success"
          className="custom-button"
          size="sm"
          >
          {props.backButtonText ? props.backButtonText : t('buttons.back')}
        </Button> }
      </ButtonToolbar>
      <ButtonToolbar style={{float:"right"}}>
        {!props.hideCancelButton && <Button
          disabled={props.disabledCancelButton ? props.disabledCancelButton : false}
          onClick={props.onCancel}
          style={!props.hideOKButton ? {marginRight: "20px"} : {marginRight: "0px"}}
          size="sm"
          variant="secondary"
          >
          {props.cancelButtonText ? props.cancelButtonText : t('buttons.cancel')}
        </Button> }
        {!props.hideOKButton && <Button
          disabled={props.disabledOKButton ? props.disabledOKButton : false}
          onClick={props.showSaveAndClose ? props.onSaveOnly : props.onOK}
          //variant="success"
          className="custom-button"
          size="sm"
          style={props.showSaveAndClose ? {marginRight: "20px"} : {marginRight: "0px"}}
          >
          {props.okButtonText ? props.okButtonText : t('buttons.ok')}
        </Button> }
        {props.showSaveAndClose && <Button
          type="submit"
          form="form-content"
          onClick={props.onOK}
          disabled={props.disabledshowSaveAndClose ? props.disabledshowSaveAndClose : false}
          size="sm"
          //variant="success"
          className="custom-button"
          >
          {props.saveAndCloseText ? props.saveAndCloseText : t('buttons.save_and_close')}
        </Button> }
      </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  )
}
export default CustomModal

export const DeleteModal = ({ onOK, onCancel, name, content, title, ...rest }) => {
  const { t } = useTranslation();
  return(
    <CustomModal
      title={t('buttons.delete')}
      content={content ? content : <div>{t('messages.want_to_delete')}</div>}
      onCancel={onCancel}
      onOK={onOK}
      backdrop="static"
      okButtonText={t('buttons.yes')}
      cancelButtonText={t('buttons.no')}

      {...rest}
    />
  )
}

CustomModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  contentStyle: PropTypes.object,
  onCancel: PropTypes.func,
  okButtonText: PropTypes.string,
  okButtonClass: PropTypes.string,
  cancelButtonText: PropTypes.string,
  bsSize:PropTypes.string,
  disabledOKButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool
};
